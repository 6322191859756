<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('project.project', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="openModal('new')"
          class="float-right"
          v-b-modal="'modal-project'"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <div
        v-for="(project, index) in projects"
        :key="index"
        cols="12"
        class="my-4 mx-4"
      >

        <!-- CARD -->

        <b-card
          :img-src="require(`@/assets/img/bg/${project.image}`)"
          img-alt="Project Image"
          img-top
          tag="article"
          class="mb-2 mx-2 custom-card custom-max-width-20rem"
        >
          <b-card-text class="mb-5">
            <h2>{{ project.ref }} - {{ project.projectShortname }}</h2>
            <h3><small class="text-muted">{{ project.projectName }}</small></h3>
            <br>
            <span>{{ project.locality }} - {{ project.country }}</span>
            <br><br>
            <small
              class="text-muted"
              v-if="project.hasOwnProperty('firstname') && project.firstname !== '' && 
            project.hasOwnProperty('lastname') && project.lastname !== ''"
            >
              Project
              Owner:
              {{ project.lastname }}
              {{ project.firstname }}</small>
          </b-card-text>
          <el-button
            icon="el-icon-position"
            @click="test()"
            circle
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="openModal('edit', project.uuid)"
            circle
            :disabled="isDisabled(project.byUserUuid)"
          ></el-button>

          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteProject(project.uuid, project.ref, project.byUserUuid)"
            circle
          ></el-button>

        </b-card>

        <!-- /: CARD -->

      </div>
    </b-row>

    <modal-integrity></modal-integrity>
    <modal-project
      :mode="modalData.mode"
      :uuid="modalData.uuid"
      :project="modalData.project"
    ></modal-project>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import ModalProject from '@/components/Modals/ModalProject.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import { isMobileMixin } from '../../mixins/isMobileMixin';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  mixins: [isMobileMixin],
  components: {
    SideBarMenu,
    IdleTimer,
    ModalIntegrity,
    ModalProject,
  },
  data() {
    return {
      show: false,
      // projects: [],
      modalData: {
        mode: '',
        uuid: '',
        project: {},
      },
      search: '',
      // loading: false,
      accessToken: '',
      // qtt: 0,
    };
  },
  computed: {
    ...mapGetters(['projects']), // Ensures 'projects' is available for use in the template
    // projects() {
    //   return this.$store.state.projects;
    // },
    qtt() {
      return this.$store.getters.qtt;
    },
  },
  mounted() {
    this.loading = true;
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    // this.fetchData();
    this.$store.dispatch('fetchProjects');
  },
  methods: {
    test() {
      this.$globalSwal.error('happens');
    },
    ...mapMutations(['SAVE_CONTEXT']),
    isDisabled(byUserUuid) {
      return byUserUuid !== this.$store.getters.loggedUser.uuid;
    },
    isProjectOwner(byUserUuid) {
      return byUserUuid == this.$store.getters.loggedUser.uuid;
    },
    resetModal() {
      this.modalData = {
        mode: '',
        uuid: '',
        project: {}, // Reset to default empty object
      };
    },
    openModal(mode, uuid = '') {
      this.resetModal();
      // Find the project using the UUID or set to default if not found
      const projectData =
        this.projects.find((project) => project.uuid === uuid) || {};

      if (projectData) {
        console.log(`projectData`);
        console.log(projectData);
        this.modalData = {
          mode: mode,
          uuid: uuid,
          project: projectData,
        };
        this.$set(this.modalData, 'project', projectData);
      }

      // Show the modal
      this.$nextTick(() => {
        this.$bvModal.show('modal-project'); // Ensure modal opens after data is set
      });
    },
    fetchData() {
      console.log('FETCH DATA');
      // INITIAL CALL TO GET ACCESS TOKEN
      axiosResource
        .get('/admin/projects/', { withCredentials: true })
        .then((response) => {
          const clgStyle = 'color: red;';
          console.log('%c' + 'fetchData: /admin/projects/', clgStyle);
          console.log(response.data.projects);
          this.projects = response.data.projects;

          this.loading = false;
          this.qtt = response.data.projects.length;
          console.log('%c' + `qtt: ${qtt}`, clgStyle);
          console.log(this.projects);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.message == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.message);
          }
        });
    },
    deleteProject(projectUuid, ref, byUserUuid) {
      if (!this.isProjectOwner(byUserUuid)) {
        swalBootsrap.fire({
          title: this.$t('project.notProjectOwner'),
          text: this.$t('project.contactAdmin'),
          confirmButtonText: 'OK',
          icon: 'warning',
        });
      } else {
        swalBootsrap
          .fire({
            title: this.$t('project.deleteTitle') + ref + '?',
            text: this.$t('project.deleteText'),
            showConfirmButton: true,
            showCancelButton: true,
            icon: 'question',
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.processAction(command);
            } else if (result.isDenied) {
              swalBootsrap.fire('Changes are not saved', '', 'info');
            }
          });
      }
    },
  },
};
</script>