<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('user.users', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="createNew"
          class="float-right"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4">
          <el-table
            v-loading="loading"
            :data="searchInTable"
            size="large"
            class="w-100"
          >
            <el-table-column
              :width="isMobile ? 66 : 100"
              fixed
            >
              <template
                slot="header"
                slot-scope="{}"
              >
              </template>
              <template slot-scope="scope">
                <el-button
                  type="plain"
                  size="mini"
                  @click="handleLookup(scope.$index, scope.row)"
                >
                  <font-awesome-icon
                    icon="fa-light fa-cog"
                    class="d-block d-md-none"
                  />
                  <span class="d-none d-md-block">Manage</span>
                </el-button>
              </template>
            </el-table-column>

            <!-- <el-table-column
              label="ID"
              prop="id.digit5"
              sortable
              width="120"
            >
            </el-table-column> -->
            <el-table-column
              :label="$t('user.table.email')"
              prop="username"
              sortable
              show-overflow-tooltip
              width="300"
            >
            </el-table-column>
            <el-table-column
              :label="$t('user.table.lastname')"
              prop="name.lastname"
              sortable
              width="160"
            >
            </el-table-column>
            <el-table-column
              :label="$t('user.table.firstname')"
              prop="name.firstname"
              sortable
              width="160"
            >
            </el-table-column>
            <el-table-column
              :label="$t('user.table.shortname')"
              prop="name.acronym4digits"
              sortable
              width="140"
            >
            </el-table-column>
            <el-table-column
              :label="$t('user.table.nature')"
              prop="nature"
              sortable
              width="140"
            >
              <template slot-scope="scope">
                <el-tag
                  :type="typeNature(scope.row.nature)"
                  disable-transitions
                >{{scope.row.nature}}</el-tag>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('user.table.entity')"
              prop="entity.name"
              sortable
              width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.entity.name .toUpperCase() }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('user.table.role')"
              prop="role"
              sortable
              width="100"
              :formatter="formatter_role"
            >

            </el-table-column>
            <el-table-column
              :label="$t('user.table.status')"
              :formatter="formatter_status"
              prop="status"
              sortable
              width="120"
            >
            </el-table-column>

          </el-table>
          <base-pagination
            class="mb-4"
            :total="this.tableData.length"
            :currentPage="this.currentPage"
            :pageSize="this.pageSize"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </b-col>
    </b-row>
    <modal-integrity></modal-integrity>
  </div>
</template>




<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import axiosResource from '@/utils/axiosResource';
// import swal from 'sweetalert2';
import BasePagination from '../../components/BasePagination.vue';
import { isMobileMixin } from '../../mixins/isMobileMixin';

export default {
  mixins: [isMobileMixin],
  components: {
    BasePagination,
    SideBarMenu,
    IdleTimer,
    ModalIntegrity,
  },
  data() {
    return {
      loading: false,
      show: false,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      search: '',
      qtt: '',
    };
  },
  computed: {
    searchInTable() {
      if (this.search != '') {
        return this.tableData.filter(
          (data) =>
            !this.search ||
            data.name.lastname
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.username.toLowerCase().includes(this.search.toLowerCase()) ||
            data.name.firstname
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.status.toLowerCase().includes(this.search.toLowerCase()) ||
            data.nature.toLowerCase().includes(this.search.toLowerCase()) ||
            data.id.digit5.toLowerCase().includes(this.search.toLowerCase()) ||
            data.name.acronym4digits
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.entity.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.entity.shortname
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      } else {
        return this.pagedTableData;
      }
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    formatter_status(row) {
      return this.$tc(`status.${row.status}`);
    },
    formatter_role(row) {
      if (row.role == 'admin') {
        return this.$tc('user.role.admin');
      } else {
        return this.$tc('user.role.user');
      }
    },
    handleSizeChange(val) {
      // console.log(`${val} items per page`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`current page: ${val}`);
      this.currentPage = val;
    },
    handleLookup(index, row) {
      const uuid = row.uuid;
      this.$router.push({ name: 'usermanage', params: { uuid: uuid } });
    },
    // handleLookup(index, row) {
    //   this.$store.commit('watchUserId', row.id.raw);
    //   this.$router.push('usermanage');
    //   // this.$router.push({
    //   //   name: 'usermanage',
    //   //   params: { userId: row.id.raw },
    //   // });
    // },
    createNew() {
      this.$router.push({ name: 'usercreateedit', params: { uuid: 'new' } });
    },
    formatter(row) {
      return row.name.lastname + ' ' + row.name.acronym4digits;
    },
    typeNature(val) {
      switch (val) {
        case 'BM':
          return 'primary';
        case 'CONTROLLER':
          return 'success';
        case 'FIRM':
          return 'info';
        default:
          return 'danger';
      }
    },
    fetchData() {
      axiosResource
        .get('/admin/users/register', { withCredentials: true })
        .then((response) => {
          const clgStyle = 'color: red;font-size:24px;';
          console.log('%c' + 'USERS', clgStyle);
          console.log(response.data);
          this.tableData = response.data;
          this.qtt = response.data.length;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.message == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.loading = true;
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
  created: function () {
    // console.log("-> Created");
    this.fetchData();
  },
};
</script>
